import { Controller } from "stimulus";
import Currency from "javascripts/currency";

export default class extends Controller {
  static targets = ["quantity", "priceTotal", "salesTax", "total"];

  connect() {}

  calcAll() {
    const price = parseInt(this.element.dataset.price, 10);
    const quantity = parseInt(this.quantityTarget.value, 10);
    const priceTotal = price * quantity;
    const salesTax = Currency.calcSalesTax(priceTotal);
    const total = priceTotal + salesTax;

    this.priceTotalTarget.value = Currency.numberToCurrency(priceTotal);
    this.salesTaxTarget.value = Currency.numberToCurrency(salesTax);
    this.totalTarget.value = Currency.numberToCurrency(total);
  }
}
