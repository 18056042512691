import PictureUploadableController from "../picture_uploadable_controller";

export default class extends PictureUploadableController {
  static targets = ["form", "newEmail", "password"];

  connect() {}

  checkRequiedPassword() {
    this.passwordTarget.dataset.parsleyRequired =
      this.element.dataset.passwordPresent !== "true" &&
      this.newEmailTarget.value
        ? "true"
        : "false";
  }

  async submit(e) {
    this.checkRequiedPassword();
    await super.submit(e);
  }
}
