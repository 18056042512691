import jQuery from "jquery";
import moment from "moment";

const parseRequirement = requirement => {
  if (isNaN(+requirement)) return parseFloat(jQuery(requirement).val()); // eslint-disable-line
  return +requirement;
};

// Greater than validator
window.Parsley.addValidator("gtIfPresent", {
  validateString(value, requirement) {
    const parsedRequirement = parseRequirement(requirement);
    if (!parsedRequirement) return true;
    return parseFloat(value) > parsedRequirement;
  },
  priority: 32
});
// Less than validator
window.Parsley.addValidator("ltIfPresent", {
  validateString(value, requirement) {
    const parsedRequirement = parseRequirement(requirement);
    if (!parsedRequirement) return true;
    return parseFloat(value) < parsedRequirement;
  },
  priority: 32
});

// Greater than equal datetime validator
window.Parsley.addValidator("datetimeGte", {
  validateString(value, requirement) {
    const reqVal = jQuery(requirement).val();
    if (!reqVal) return true;
    return moment(value) >= moment(reqVal);
  },
  priority: 32
});
// Less than equal datetime validator
window.Parsley.addValidator("datetimeLte", {
  validateString(value, requirement) {
    const reqVal = jQuery(requirement).val();
    if (!reqVal) return true;
    return moment(value) <= moment(reqVal);
  },
  priority: 32
});
