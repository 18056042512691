import EXIF from "exif-js/exif";

export default class ImageLoader {
  rotate(org, orientation) {
    let width = 0;
    let height = 0;
    let angle = 0;

    switch (orientation) {
      case 3:
        ({ width, height } = org);
        angle = Math.PI;
        break;
      case 6:
        ({ width: height, height: width } = org);
        angle = Math.PI / 2;
        break;
      case 8:
        ({ width: height, height: width } = org);
        angle = -Math.PI / 2;
        break;
      default:
        return org;
    }

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(angle);
    ctx.drawImage(org, -org.width / 2, -org.height / 2);

    return canvas;
  }

  async load(url, type, preViewArea) {
    preViewArea.style.backgroundImage = `url('/images/ajax-loader.gif')`;
    preViewArea.style.backgroundSize = '50px';

    const org = await new Promise((resolve, reject) => {
      const img = document.createElement("img");
      img.onload = () => resolve(img);
      img.onerror = e => reject(e);
      img.src = url;
    });

    const orientation = await new Promise(resolve => {
      if (type === "image/jpeg") {
        EXIF.getData(org, () => {
          resolve(EXIF.getTag(org, "Orientation") || 1);
        });
      } else {
        resolve(1);
      }
    });

    return this.rotate(org, orientation);
  }
}
