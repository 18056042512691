$(() => {
  const itemList = [
    "赤カレイ",
    "ハタハタ",
    "エテガレイ",
    "カワハギ",
    "赤ムツ・のどぐろ",
    "真あじ",
    "クロザコエビ",
    "松葉ガニ",
    "コウイカ",
    "スルメイカ",
    "バイ貝",
    "サザエ"
  ];

  $("#myForm").submit(evt => {
    evt.preventDefault();

    let imgPath;
    const formData = new FormData($("#myForm")[0]);

    // 判定中アイコンを表示する
    document.getElementById("result_unit").src = "/images/loading.gif";
    document.getElementById("title").innerText = "判定中...";

    // Ajaxで送信
    $.ajax({
      url: "https://uuuo-api.herokuapp.com/upload",
      method: "POST",
      // dataType: 'json',
      // dataに FormDataを指定
      data: formData,
      // Ajaxがdataを整形しない指定
      processData: false,
      // contentTypeもfalseに指定
      contentType: false
    })
      .done(res => {
        // 送信成功！
        const itemId = Number(res.item_id) + 1;
        if (itemId === 0) {
          document.getElementById("result_unit").src = "/images/fail_class.png";
          $("#result_unit").css("width", "200px");
          document.getElementById("title").innerHTML =
            "写真を入力してください!";
        } else {
          imgPath = `/images/class/${itemId}.jpg`;
          document.getElementById("result_unit").src = imgPath;
          $("#result_unit").css("width", "200px");
          document.getElementById("title").innerHTML =
            `入力された写真は<br>「${itemList[res.item_id]}」<br>` +
            `と判別されました！`;
        }
      })
      .fail(() => {
        // 送信失敗！
        document.getElementById("result_unit").src = "/images/fail_class.png";
        $("#result_unit").css("width", "200px");
        document.getElementById("title").innerHTML =
          "通信に失敗しました。<br>しばらく経ってから再度ご利用ください!";
      });
    return false;
  });
});
