import ClipboardJS from "clipboard";
import toastr from "toastr";

$(document).ready(() => {
  const clipboard = new ClipboardJS(".btn.copyable");

  clipboard.on("success", () => {
    toastr.success("コピーしました。");
  });

  clipboard.on("error", () => {
    toastr.error("コピーに失敗しました。");
  });
});
