import { Controller } from "stimulus";
import moment from "moment";

export default class extends Controller {
  static targets = ["hour", "min", "sec", "targetDatetime"];

  connect() {
    this.countdown();
  }

  countdown() {
    this.updateTime(0);
    let diffTime = this.getDiffTime();
    if (diffTime === 0) return;
    const timer = setInterval(() => {
      diffTime = this.getDiffTime();
      this.updateTime(diffTime);
      if (diffTime === 0) {
        clearInterval(timer);
        window.location.reload();
      }
    }, 1000);
  }

  updateTime(diff) {
    const duration = moment.duration(diff);
    this.hourTarget.textContent = duration.hours();
    this.minTarget.textContent = duration.minutes();
    this.secTarget.textContent = duration.seconds();
  }

  getDiffTime() {
    const diffTime = moment(this.targetDatetimeTarget.textContent).diff(
      moment()
    );
    return diffTime < 0 ? 0 : diffTime;
  }
}
