import { Controller } from "stimulus";
import axios from "axios/index";

export default class extends Controller {
  static targets = ["mutable", "loading"];

  connect() {}

  async loadMore(e) {
    e.preventDefault();

    const { contentSelector } = this.element.dataset;
    const mutable = $(this.mutableTarget);
    const loading = $(this.loadingTarget);

    loading.show();
    mutable.hide();

    try {
      const response = await axios.get(e.target.href, {
        headers: { Accept: "text/html" }
      });
      const dom = $(response.data);
      $(contentSelector).append($(contentSelector, dom).children());
      mutable.replaceWith($(".load-more .mutable", dom));
    } finally {
      mutable.show();
      loading.hide();
    }
  }
}
