import Pica from "pica";

const pica = Pica();

export default class ImageResizer {
  calcScale(src, size) {
    return Math.sqrt(size / (src.width * src.height));
  }

  async resize(src, size) {
    const scale = this.calcScale(src, size);

    const dst = document.createElement("canvas");
    dst.width = Math.floor(src.width * scale);
    dst.height = Math.floor(src.height * scale);

    await pica.resize(src, dst);

    dst.toBlobSafely = (type, quality) => pica.toBlob(dst, type, quality);

    return dst;
  }
}
