import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "allTab",
    "orderedTab",
    "shippedTab",
    "acceptedTab",
    "cancelledTab"
  ];

  connect() {
    this.update();
  }

  update() {
    switch (this.element.dataset.statusQuery) {
      case "ordered":
        this.orderedTabTarget.classList.add("active");
        break;
      case "shipped":
        this.shippedTabTarget.classList.add("active");
        break;
      case "accepted":
        this.acceptedTabTarget.classList.add("active");
        break;
      case "cancelled":
        this.cancelledTabTarget.classList.add("active");
        break;
      default:
        this.allTabTarget.classList.add("active");
    }
  }
}
