import { Controller } from 'stimulus';
import axios from 'axios/index';

export default class extends Controller {
  static targets = ['postCode',
    'address',
    'billingOrgName',
    'billingUserName',
    'shipToInfo',
    'alertMessage',
    'organizationName'
  ]

  connect() {
    this.changeRelationship();
  }

  async changeRelationship() {
    let deliveryStatementId = $('#js-delivery-statement-id').val() || ''
    let tradingRelationshipId = $('#trading_relationship_select').val();
    let organiztionId = $('#delivery_statement_organization_id').val();
    let billing_user_id = $('#delivery_statement_billing_user_id').val();
    let purchaseIds = '';
    $('.v2-delivery-purchase-select').each((_, select) => {
      if (select.value != '' && select.value != undefined) {
        let value = String(select.value);
        if (purchaseIds.length > 0) {
          value = ',' + value;
        }
        purchaseIds += value;
      }
    })
    if (organiztionId == '' && (tradingRelationshipId === '' || tradingRelationshipId == null)) {
      this.alertMessageTarget.textContent = '納品先ルートを設定してください';
      this.postCodeTarget.textContent = '';
      this.addressTarget.textContent = '';
      this.billingOrgNameTarget.textContent = '';
      this.billingUserNameTarget.textContent = '';
      this.shipToInfoTarget.textContent = '';
      return;
    }
    const response = await axios.get(
      `admin/v2_delivery_statements/organization_addresses?trading_relationship_id=${tradingRelationshipId}&billing_user_id=${billing_user_id}&delivery_statement_id=${deliveryStatementId}&purchase_ids=${purchaseIds}`,
    );
    const data = response.data

    let selectedBillingUser;
    if ($('#js-delivery-statement-action').val() == 'show') {
      selectedBillingUser = {
        orgPostCode: data.billingOrgPostCode,
        orgAddress: data.billingOrgAddress,
        orgName: data.billingOrgName,
        name: data.billingUserName,
      }
    } else if (data.useInsteadOfPurchasedUser) {
      $('#billing-user-select').show();
      $('#delivery_statement_billing_user_id > option').remove();
      data.purchasers.forEach((purchaser) => {
        $('#delivery_statement_billing_user_id').append($('<option>').html(purchaser.name).val(purchaser.id));
      })
      selectedBillingUser = data.purchasers.find((purchaser) => String(purchaser.id) == (billing_user_id || $('#delivery_statement_billing_user_id').val()));
    } else {
      $('#billing-user-select').hide();
      $('#delivery_statement_billing_user_id > option').remove();
      selectedBillingUser = data.purchasers[0]; // 送り先担当者に購入担当者を使用フラグが有効ではない場合はpurchasersには帳合関係の帳合先ユーザー(billing_user)のみ入っている
      $('#delivery_statement_billing_user_id').append($('<option>').html(selectedBillingUser.name).val(selectedBillingUser.id));
    }

    $(`#delivery_statement_billing_user_id option[value='${selectedBillingUser.id}']`).attr('selected', 'selected');
    $('#billing-user-id-hidden').val(selectedBillingUser.id)

    this.alertMessageTarget.textContent = '';
    this.postCodeTarget.textContent = `〒 ${selectedBillingUser.orgPostCode}`;
    this.addressTarget.textContent = selectedBillingUser.orgAddress;
    this.billingOrgNameTarget.textContent = selectedBillingUser.orgName;
    this.billingUserNameTarget.textContent = `${selectedBillingUser.name} 様`;
    let shipToInfo = ''
    if (data.billingOrgId != data.shipToOrgId) {
      if (data.middleClientOrgId != null) {
        shipToInfo = `販売先: ${data.middleClientOrgName}/${data.middleClientUserName}様(${data.shipToOrgDisplayName}様分)`
      } else {
        shipToInfo = `販売先: ${data.shipToOrgName}/${data.shipToUserName}様`
      }
    }
    this.shipToInfoTarget.textContent = shipToInfo;
    this.organizationNameTarget.textContent = data.shipToOrgName
  }

  async changePurchaseId(event) {
    this.changeRelationship();

    const currentId = event.currentTarget.id;
    const index = parseInt(currentId.replace(/[^0-9^\.]/g,""));
    if ($(`[id$=${index}_fish_type_id]`).val() !== '') {
      // すでに入力済みの場合は自動設定しない
      return;
    }
    const purchaseId = $(`#${event.currentTarget.id}`).val();
    const response = await axios.get(
      `admin/v2_delivery_statements/purchase_rows?purchase_id=${purchaseId}`,
    );
    const { data } = response;

    $(`[id$=${index}_tenant_id]`).val(data.tenantId);
    $(`[id$=${index}_fish_type_id]`).val(data.fishTypeId);
    $(`[id$=${index}_size_name]`).val(data.sizeName);
    $(`[id$=${index}_case_quantity]`).val(data.caseQuantity);
    $(`[id$=${index}_quantity]`).val(data.quantity);
    $(`[id$=${index}_price_per_unit]`).val(data.pricePerUnit).trigger('change');
    $(`[id$=${index}_price]`).val(data.price);
    $(`[id$=${index}_sales_method]`).val(data.salesMethod);
    $(`[id$=${index}_store_name]`).val(data.storeName);
    $(`[id$=${index}_remark]`).val(data.remark_text);
    $(`label[for$=${index}_producing_center_name]`).text(data.producingCenterName);
    $(`label[for$=${index}_fish_type_id]`).text(data.productName);
    $(`label[for$=${index}_price]`).text(`¥${Number(data.price).toLocaleString()}`);
    $(`label[for$=${index}_producing_center_name]`).text(data.producingCenterName);
  }
}
