import ImageLoader from "javascripts/image_loader";
import ImageResizer from "javascripts/image_resizer";

import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  get MAX_IMAGE_SIZE() {
    return 1080 * 590;
  }

  connect() {
    // $(`#picture${i}`).next()[0].name = $(`#picture${i}`)[0].name;
    this.resizedImage = null;
  }

  async resize(url, type, filename, i) {
    const loader = new ImageLoader();
    const resizer = new ImageResizer();
    const previewArea = $(`#picture${i}`)
      .parent()
      .find("span")[0];
    const src = await loader.load(url, type, previewArea);

    const scale = resizer.calcScale(src, this.MAX_IMAGE_SIZE);
    if (scale >= 1.0) {
      this.setPreviewImage(src.src, i);
      return;
    }

    const dst = await resizer.resize(src, this.MAX_IMAGE_SIZE);

    this.setPreviewImage(dst.toDataURL(), i);
    const blob = await dst.toBlobSafely("image/jpeg", 0.9);
    this.resizedImage = new File([blob], filename, {
      type: blob.type,
      lastModified: new Date()
    });
  }

  async preview(i) {
    if ($("#multiple_picture")[0].files && $("#multiple_picture")[0].files[i]) {
      const file = $("#multiple_picture")[0].files[i];
      if (file.type.startsWith("image/")) {
        await this.resize(
          URL.createObjectURL(file),
          file.type,
          file.name || "picture",
          i
        );
      } else if (file.type.startsWith("video/")) {
        this.setPreviewImage("/images/film.svg");
      }
    } else {
      this.setPreviewImage("");
    }
  }

  async changed(i) {
    this.resizedImage = null;
    await this.preview(i);
    $(`#picture${i}`)
      .next()
      .attr("disabled", "disabled");
    $(`#picture${i}`)
      .next()
      .next()
      .removeAttr("disabled");
    if (this.element.dataset.required === "true") {
      $(`#picture${i}`).attr("required", "required");
    }
  }

  setPreviewImage(url, i) {
    $(`#picture${i}`)
      .parent()
      .find("span")[0].style.backgroundImage = `url('${url}')`;
    $(`#picture${i}`)
      .parent()
      .find("span")[0].style.backgroundSize = "cover";
    $(`#picture${i}`)
      .parent()
      .find("span")[0].style.backgroundColor = url ? "#ccc" : "transparent";
  }
}
