export default class Currency {
  static SALES_TAX_RATE_100 = 8;

  static calcSalesTax(value) {
    return Math.floor(value * Currency.SALES_TAX_RATE_100 / 100);
  }

  static numberWithDelimiter(value) {
    return Number(value).toLocaleString(undefined, {
      maximumFractionDigits: 20
    });
  }

  static numberToCurrency(value) {
    return Number(value).toLocaleString("ja-JP", {
      style: "currency",
      currency: "JPY"
    });
  }
}
