import PictureUploadableController from "../picture_uploadable_controller";

export default class extends PictureUploadableController {
  static targets = ["price", "fee", "profit"];

  connect() {
    this.feeTarget.textContent = "-";
    this.profitTarget.textContent = "-";
    if (this.priceTarget.value > 0) {
      this.calcComission();
    }
  }

  calcComission() {
    const fee = this.calcPercent(this.priceTarget.value, 15);
    this.feeTarget.textContent = fee;
    this.profitTarget.textContent = this.priceTarget.value - fee;
  }

  /**
   * %を計算する
   * 1円未満切り捨て
   * @param value 計算元の値
   * @param per ％（整数）
   * @returns {number}
   */
  calcPercent(value, per) {
    return Math.floor((value * per) / 100);
  }
}
