import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output", "output2", "file"];

  async preview() {
    const reader = new FileReader();
    if (this.fileTarget.files && this.fileTarget.files[0]) {
      const file = this.fileTarget.files[0];
      reader.readAsDataURL(file);
      $(this.output2Target).attr("src", URL.createObjectURL(file));
    }
  }
}
