import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["counter", "textField"];

  connect() {
    this.count();
  }

  count() {
    this.counterTarget.textContent = this.textFieldTarget.value.length;
  }
}
