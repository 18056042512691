import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "containerSelector",
    "weightPerContainer",
    "weightPerPiece",
    "fishingPortSelector",
    "closedAt"
  ];

  connect() {
    $(this.containerSelectorTarget)
      .select2()
      .on("change", () => this.containerChanged());

    $(this.fishingPortSelectorTarget)
      .select2()
      .on("change", () => this.fishingPortChanged());

    const positiveButton = document.querySelector(
      ".selling-container-form .modal .btn-primary"
    );
    positiveButton.addEventListener("click", () => this.setWeightData());
    positiveButton.addEventListener("click", () => this.setTimeData());
  }

  setWeightData() {
    if (this.containerSelectorTarget.selectedOptions.length === 0) {
      return;
    }

    const weightPerContainer =
      this.containerSelectorTarget.selectedOptions[0].dataset
        .weight_per_container || "";
    this.weightPerContainerTarget.value = weightPerContainer;
    this.weightPerContainerTarget.dataset.weightPerContainerTarget = weightPerContainer;

    const weightPerPiece =
      this.containerSelectorTarget.selectedOptions[0].dataset
        .weight_per_piece || "";
    this.weightPerPieceTarget.value = weightPerPiece;
    this.weightPerPieceTarget.dataset.weightPerPieceTarget = weightPerPiece;
  }

  setTimeData() {
    if (this.fishingPortSelectorTarget.selectedOptions.length === 0) {
      return;
    }

    const timeForFishingPort =
      this.fishingPortSelectorTarget.selectedOptions[0].dataset
        .default_closed_at || "";
    this.fishingPortSelectorTarget.value = timeForFishingPort;
    this.fishingPortSelectorTarget.dataset.closedAtTarget = timeForFishingPort;
  }

  containerChanged() {
    if (
      this.weightPerContainerTarget.value &&
      this.weightPerContainerTarget.value !==
        this.weightPerContainerTarget.dataset.weightPerContainerTarget
    ) {
      $(".selling-information-form .modal").modal();
    } else {
      this.setWeightData();
    }
  }

  fishingPortChanged() {
    if (
      this.fishingPortSelectorTarget.value &&
      this.fishingPortSelectorTarget.value !==
        this.fishingPortSelectorTarget.dataset.closedAtTarget
    ) {
      $(".selling-information-form .modal").modal();
    } else {
      this.setTimeData();
    }
  }
}
