window.ParsleyConfig = {
  errorClass: "has-error",
  successClass: "success",
  classHandler(ParsleyField) {
    return ParsleyField.$element.parents(".form-group");
  },
  errorsContainer(ParsleyField) {
    return ParsleyField.$element.parents(".common-input");
  },
  errorsWrapper: "<div>",
  errorTemplate: '<span class="help-block"></span>'
};
