import ImageLoader from "javascripts/image_loader";
import ImageResizer from "javascripts/image_resizer";
import axios from "axios/index";

import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = [
    "file",
    "preview",
    "pictureId",
    "removeAttachmentId",
    "selectButton"
  ];

  get MAX_IMAGE_SIZE() {
    return 1080 * 590;
  }

  connect() {
    this.pictureIdTarget.name = this.fileTarget.name;
    this.resizedImage = null;
  }

  async resize(url, type, filename) {
    const loader = new ImageLoader();
    const resizer = new ImageResizer();
    const previewArea = this.previewTarget;
    const src = await loader.load(url, type, previewArea);

    const scale = resizer.calcScale(src, this.MAX_IMAGE_SIZE);
    if (scale >= 1.0) {
      this.setPreviewImage(src.src);
      return;
    }

    const dst = await resizer.resize(src, this.MAX_IMAGE_SIZE);

    this.setPreviewImage(dst.toDataURL());
    const blob = await dst.toBlobSafely("image/jpeg", 0.9);
    this.resizedImage = new File([blob], filename, {
      type: blob.type,
      lastModified: new Date()
    });
  }

  async preview() {
    if (this.fileTarget.files && this.fileTarget.files[0]) {
      const file = this.fileTarget.files[0];
      if (file.type.startsWith("image/")) {
        await this.resize(
          URL.createObjectURL(file),
          file.type,
          file.name || "picture"
        );
      } else if (file.type.startsWith("video/")) {
        this.setPreviewImage("/images/film.svg");
      }
    } else {
      this.setPreviewImage("");
    }
  }

  async changed() {
    if (this.fileTarget.files[0] === undefined) {
      return;
    }
    this.resizedImage = null;
    await this.preview();

    $(this.pictureIdTarget).attr("disabled", "disabled");
    $(this.removeAttachmentIdTarget).removeAttr("disabled");
    if (this.element.dataset.required === "true") {
      $(this.fileTarget).attr("required", "required");
    }
  }

  async removePicture() {
    $(this.fileTarget).val("");
    this.resizedImage = null;

    this.previewTarget.style.backgroundImage = "none";
    this.previewTarget.style.backgroundSize = "cover";
    this.previewTarget.style.backgroundColor = "transparent";

    $(this.pictureIdTarget).attr("disabled", "disabled");
    $(this.removeAttachmentIdTarget).removeAttr("disabled");
    if (this.element.dataset.required === "true") {
      $(this.fileTarget).attr("required", "required");
    }
  }

  setPreviewImage(url) {
    if (url === "" && this.fileTarget.files[0] === undefined) {
      return;
    }
    this.previewTarget.style.backgroundImage = `url('${url}')`;
    this.previewTarget.style.backgroundSize = "cover";
    this.previewTarget.style.backgroundColor = url ? "#ccc" : "transparent";
  }

  enableDirectCameraAccess() {
    this.fileTarget.setAttribute("capture", "environment");
  }

  disableDirectCameraAccess() {
    this.fileTarget.removeAttribute("capture");
  }

  async uploadResizedImage() {
    if (this.fileTarget.disabled || !this.resizedImage) {
      return true;
    }

    this.fileTarget.disabled = "disabled";

    const formData = new FormData();
    formData.append("files[]", this.resizedImage);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    try {
      const response = await axios.post("/blobs", formData, config);
      const { data } = response;

      const input = document.createElement("input");
      input.name = this.fileTarget.name;
      input.type = "hidden";
      [input.value] = data.blob_ids;
      this.element.appendChild(input);
    } catch (ex) {
      return false;
    }

    return true;
  }

  static async uploadResizedImages(application) {
    const results = ApplicationController.getInstances(
      application,
      "picture-uploader"
    ).map(controller => controller.uploadResizedImage());

    return (await Promise.all(results)).reduce((x, y) => x && y);
  }
}
