import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slide"];

  initialize() {
    this.showCurrentSlide();
  }

  next() {
    if (this.index < this.slideTargets.length - 1) this.index += 1;
  }

  previous() {
    if (this.index > 0) this.index -= 1;
  }

  showCurrentSlide() {
    this.slideTargets.forEach((el, i) => {
      el.classList.toggle("slide--current", this.index === i);
    });
  }

  get index() {
    return parseInt(this.data.get("index"), 10);
  }

  set index(value) {
    this.data.set("index", value);
    this.showCurrentSlide();
  }
}
