import { Controller } from "stimulus";
import Swiper from "swiper";

export default class extends Controller {
  connect() {
    this.swiper = new Swiper(".swiper-container", {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      on: {
        slideChange: () => this.slideChanged()
      }
    });
  }

  onClickThumb(event) {
    this.element.querySelectorAll(".carousel-thumbnail li").forEach(li => {
      li.classList.remove("active");
    });
    event.target.parentNode.classList.add("active");
    const index = event.target.getAttribute("data-index");
    this.swiper.slideTo(index);
  }

  slideChanged() {
    this.element.querySelectorAll(`.swiper-slide video`).forEach(video => {
      const index = parseInt(video.getAttribute("data-index"), 10);
      if (this.swiper.realIndex === index) {
        video.play();
      } else {
        video.pause();
      }
    });
  }
}
