import { Controller } from "stimulus";
import Swiper from "swiper";

export default class extends Controller {
  connect() {
    this.swiper = new Swiper(".swiper-container", {
      slidesPerView: 4,
      spaceBetween: 20,
      freeMode: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      breakpoints: {
        980: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        668: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        480: {
          slidesPerView: 1,
          spaceBetween: 10
        }
      }
    });
  }
}
