import PictureUploadableController from "../picture_uploadable_controller";
import PictureUploaderBulkController from "../picture_uploader/picture_uploader_controller_bulk";

export default class extends PictureUploadableController {
  static targets = ["fishTypeSelector", "detail", "pictures"];

  connect() {
    $(this.fishTypeSelectorTarget)
      .select2()
      .on("change", () => this.fishTypeChanged());

    const positiveButton = document.querySelector(
      ".selling-information-form .modal .btn-primary"
    );
    positiveButton.addEventListener("click", () =>
      this.setDescriptionTemplate()
    );
  }

  fishTypeChanged() {
    if (
      this.detailTarget.value &&
      this.detailTarget.value !== this.detailTarget.dataset.descriptionTemplate
    ) {
      $(".selling-information-form .modal").modal();
    } else {
      this.setDescriptionTemplate();
    }
  }

  setDescriptionTemplate() {
    if (this.fishTypeSelectorTarget.selectedOptions.length === 0) {
      return;
    }

    const descriptionTemplate =
      this.fishTypeSelectorTarget.selectedOptions[0].dataset
        .description_template || "";

    this.detailTarget.value = descriptionTemplate;
    this.detailTarget.dataset.descriptionTemplate = descriptionTemplate;
    document
      .getElementById("selling_information_detail")
      .dispatchEvent(new Event("change"));
  }

  setPictures() {
    if (this.picturesTarget.files && this.picturesTarget.files[0]) {
      for (let i = 0; i < this.picturesTarget.files.length; i += 1) {
        const pictureUploaderBulk = new PictureUploaderBulkController();
        pictureUploaderBulk.changed(i);
        if (i >= 3) {
          break;
        }
      }
    }
  }

  removePictures() {
    document.getElementById("multiple_picture").value = "";
    for (let i = 0; i < 4; i += 1) {
      document.getElementById(`picture${i}`).value = "";
      const preview = $(`#picture${i}`)
        .parent()
        .find("span");
      preview.css({
        backgroundImage: "none",
        backgroundSize: "cover",
        backgroundColor: "transparent"
      });
    }
  }
}
