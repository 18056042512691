// CSS
import "select2/dist/css/select2.min.css";
import "select2-bootstrap-theme/dist/select2-bootstrap.min.css";

// js
import Select2 from "select2"; // eslint-disable-line
import "select2/dist/js/i18n/ja";

// options
// @see https://select2.org/configuration/options-api
$.fn.select2.defaults.set("allowClear", true);
$.fn.select2.defaults.set("placeholder", "");
$.fn.select2.defaults.set("width", "100%");
$.fn.select2.defaults.set("theme", "bootstrap");

$(document).ready(() => {
  const select2Target = $("select.js-searchable-select");
  if (select2Target.length) {
    const placeholder = select2Target.data("placeholder");
    select2Target.select2({ placeholder });
  }

  $(document).on("focus", ".select2.select2-container", function(e) {
    if (
      e.originalEvent &&
      $(this).find(".select2-selection--single").length > 0
    ) {
      $(this)
        .siblings("select")
        .select2("open");
    }
  });
});
