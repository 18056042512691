import { Controller } from "stimulus";
import axios from "axios/index";
import toastr from "toastr";

export default class extends Controller {
  static targets = [
    "shipButton",
    "acceptButton",
    "cancelButton",
    "orderedStatus",
    "shippedStatus",
    "acceptedStatus",
    "cancelledStatus"
  ];

  connect() {
    this.update(this.element.dataset.status);
  }

  async accept(e) {
    try {
      await axios.get(e.target.dataset.href);
      this.update("accepted");
      toastr.success("受取完了しました。");
    } catch (ex) {
      toastr.error("操作を完了できませんでした。");
    }
  }

  async ship(e) {
    try {
      await axios.get(e.target.dataset.href);
      this.update("shipped");
      toastr.success("発送完了しました。");
    } catch (ex) {
      toastr.error("操作を完了できませんでした。");
    }
  }

  async cancel(e) {
    try {
      await axios.get(e.target.dataset.href);
      this.update("cancelled");
      toastr.success("注文をキャンセルしました。");
    } catch (ex) {
      toastr.error("操作を完了できませんでした。");
    }
  }

  update(status) {
    const { mode } = this.element.dataset;

    this.element.dataset.status = status;

    this.acceptButtonTarget.classList.toggle(
      "hidden",
      mode !== "placed_order" || status !== "shipped"
    );
    this.shipButtonTarget.classList.toggle(
      "hidden",
      mode !== "received_order" || status !== "ordered"
    );
    this.cancelButtonTarget.classList.toggle(
      "hidden",
      mode !== "received_order" || status !== "ordered"
    );

    this.orderedStatusTarget.classList.toggle("hidden", status !== "ordered");
    this.shippedStatusTarget.classList.toggle("hidden", status !== "shipped");
    this.acceptedStatusTarget.classList.toggle("hidden", status !== "accepted");
    this.cancelledStatusTarget.classList.toggle(
      "hidden",
      status !== "cancelled"
    );
  }

  showDetails() {
    $(".order-list-item__address__detail", this.element).collapse("toggle");
  }
}
