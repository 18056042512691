import { Controller } from "stimulus";

export default class ApplicationController extends Controller {
  getSiblings() {
    return ApplicationController.getInstances(
      this.application,
      this.identifier
    ).filter(controller => controller !== this);
  }

  static getInstances(application, identifier) {
    return Array.from(document.getElementsByClassName(identifier))
      .map(element =>
        application.getControllerForElementAndIdentifier(element, identifier)
      )
      .filter(controller => controller);
  }
}
