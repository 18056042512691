import { Controller } from "stimulus";
import toastr from "toastr";

import PictureUploaderController from "./picture_uploader/picture_uploader_controller";

export default class extends Controller {
  isValid(form) {
    return (
      form.checkValidity() &&
      $(form)
        .parsley()
        .isValid()
    );
  }

  async submit(e) {
    e.preventDefault();

    const form = e.target;

    if (!this.isValid(form)) {
      return;
    }
    const uploaded = await PictureUploaderController.uploadResizedImages(
      this.application
    );
    if (!uploaded) {
      toastr.error("ファイルのアップロードに失敗しました。");
      return;
    }
    form.submit();
  }
}
